<template>
  <v-app id="inspire">

    <Nav v-if="token != null && token != ''" />
    <v-main id="main" style="background-color: #10007d">
      <v-container fluid style="background-color: #f2f2f2">
        <tarjeta v-if="token != null && token != ''" />
        <router-view></router-view>
      </v-container>
      {{a}}
      <Footer />
    </v-main>
  </v-app>
</template>
<script>
  import Nav from "./components/Nav";
  import Footer from "./components/Footer";
  import tarjeta from "./components/view/Tarjeta";

  import {
    mapActions,
    mapState
  } from "vuex";

  export default {
    name: "App",
    components: {
      Nav,
      Footer,
      tarjeta,
    },
    computed: {
      a: function () {
        if ((this.token==null)&&(document.getElementsByClassName("main").length != 0)) {
          let elemento = document.getElementById("main");
          elemento.classList.remove('main');
        }
        let a = '';
        return a;
      },
      ...mapState(["token"]),
    },
    methods: {
      ...mapActions(['leerToken']),
    },
    created() {
      this.leerToken();
    },
  };
</script>