<template>
  <div>
    <v-list-item class="mt-3">
      <img src="@/assets/Icono.png" class="pw-mundo" />
      <img src="@/assets/Logo-sams-light.png" class="pw-logo" />
    </v-list-item>

    <v-list dense rounded class="mt-10">
      <v-list-item to="/inicio" class="custom-tile">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Inicio</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-menu offset-x transition="slide-x-transition" :close-on-content-click="false" open-on-hover>
        <template v-slot:activator="{ on, attrs } ">
          <v-list-item v-bind="attrs" v-on="on" class="v-list-menu" :class="{'v-list-item--active': accesos}"
            v-if="editPerfil.permiso[0] == 0 ">
            <v-list-item-icon>
              <v-icon>mdi-account-key</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Accesos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list v-for="(list,k) in menuAccesos" :key="k">
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="list.title" class="black--text"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="(item, key) in list.menu" :key="key" :to="item.url" link>
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item.title" class="black--text"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-menu>

      <v-menu offset-x transition="slide-x-transition" :close-on-content-click="false" open-on-hover>
        <template v-slot:activator="{ on, attrs } ">
          <v-list-item v-bind="attrs" v-on="on" class="v-list-menu" :class="{'v-list-item--active': items}">
            <v-list-item-icon>
              <v-icon>mdi-clipboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Items</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list v-for="(list,k) in menuItems" :key="k">
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="list.title" class="black--text"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="(item, key) in list.menu" :key="key" :to="item.url" link>
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item.title" class="black--text"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-menu>

      <v-menu offset-x transition="slide-x-transition" :close-on-content-click="false" open-on-hover>
        <template v-slot:activator="{ on, attrs } ">
          <v-list-item v-bind="attrs" v-on="on" class="v-list-menu" :class="{'v-list-item--active': procesos}">
            <v-list-item-icon>
              <v-icon>mdi-sitemap</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Procesos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list>
          <v-list-item v-for="(proceso, key) in menuProcesos" :key="key" :to="proceso.url" link>
            <v-list-item-icon>
              <v-icon v-text="proceso.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="proceso.title"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-x transition="slide-x-transition" :close-on-content-click="false" open-on-hover>
        <template v-slot:activator="{ on, attrs } ">
          <v-list-item v-bind="attrs" v-on="on" class="v-list-menu" :class="{'v-list-item--active': configuracion}"
            v-if="editPerfil.permiso[0] == 0 ">
            <v-list-item-icon>
              <v-icon>mdi-cog </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Configuración</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list>
          <v-list-item v-for="(item, key) in menuConfiguracion" :key="key" :to="item.url" link>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list>
    {{menu}}
  </div>
</template>
<style scoped>
  .pw-mundo {
    width: 80px;
  }

  .pw-logo {
    width: 150px;
  }

  .v-navigation-drawer__content div .v-list {
    padding: 0 0 0 15px;
  }

  .v-list--rounded .v-list-item::before,
  .v-list--rounded .v-list-item {
    border-radius: 35px 0 0 35px !important;
    padding: 10px 16px;
  }

  .v-list-item .v-list-item__content .v-list-item__title {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  .nav-mini .v-navigation-drawer__content div .v-list {
    padding: 0 15px 0 15px;
  }

  .nav-mini .v-navigation-drawer__content div .v-list--rounded .v-list-item::before,
  .nav-mini .v-navigation-drawer__content div .v-list--rounded .v-list-item {
    border-radius: 10px !important;
  }

  .nav-mini .v-navigation-drawer__content div .v-list--rounded .v-list-item .v-list-item__icon {
    margin-left: 5px;
  }

  .v-list-item--active {
    background: #f2f2f2;
  }

  .v-list-item--active .v-list-item__icon,
  .v-list-item--active .v-list-item__content {
    color: black;
  }

  /**animacion menu de navegacion hover */
  .v-list-item--link::before,
  .v-list-item--link::after {
    position: absolute;
    content: " ";
    top: 0;
    bottom: 0;
    left: 1000%;
    right: 0;
    transition: .35s ease-out !important;
    z-index: -1;
  }

  .v-list-item--link:after {
    background-color: #f2f2f2;
    border-radius: 35px 0 0 35px !important;
  }

  .nav-mini .v-navigation-drawer__content div .v-list--rounded .v-list-item--link:after {
    border-radius: 10px !important;
  }

  .v-list-item--link:hover::after,
  .v-list-item--link:hover::before {
    left: 0;
  }

  .v-list-item--link:hover .v-list-item__icon .v-icon,
  .v-list-item--link:hover .v-list-item__content,
  .v-list-item--link:hover .v-list-item__title {
    color: black;
    transition: .5s ease-out !important;
  }
</style>
<script>
  import {
    mapState
  } from "vuex";
  export default {
    name: "NavContenido",
    data: () => ({
      /**Obsiones menu de accesos */
      accesos: false,
      menuAccesos: [{
          title: "Principal",
          menu: [{
            icon: "mdi-account",
            title: 'Usuarios',
            url: '/usuario'
          }]
        },
        {
          title: "Componentes",
          menu: [{
              icon: "mdi-account-hard-hat",
              title: 'Cargo',
              url: '/cargo'
            },
            {
              icon: "mdi-account-group",
              title: 'Grupo',
              url: '/grupo'
            },
            {
              icon: "mdi-account-convert",
              title: 'Rol',
              url: '/rol'
            },
            {
              icon: "mdi-account-tie",
              title: 'Tretamiento',
              url: '/tratamiento'
            }
          ]
        }
      ],

      /**Obsiones menu de items */
      items: false,
      menuItems: [{
          title: "Principal",
          menu: [{
              icon: "mdi-wrench",
              title: 'Equipos',
              url: '/equipo'
            },
            {
              icon: "mdi-clipboard-text-play",
              title: 'Asignar',
              url: '/asignar'
            }
          ]
        },
        {
          title: "Componentes",
          menu: [{
              icon: "mdi-clipboard-alert",
              title: 'Tipo Item',
              url: '/tipo_item'
            },
            {
              icon: "mdi-clipboard-arrow-right",
              title: 'Uso Item',
              url: '/uso_item'
            },
            {
              icon: "mdi-clipboard-check-multiple",
              title: 'Estado Item',
              url: '/estado_item'
            },
            {
              icon: "mdi-clipboard-flow",
              title: 'Estado Remision',
              url: '/estado_remision'
            },
            {
              icon: "mdi-format-list-bulleted-type",
              title: 'Tipo Equipo',
              url: '/tipo_equipo'
            }
          ]
        }
      ],

      /**Obsiones menu de procesos */
      procesos: false,
      menuProcesos: [{
        icon: "mdi-shield-search",
        title: 'Inspección',
        url: '/inspeccion'
      }],

      /**Obsiones menu de configuracion */
      configuracion: false,
      menuConfiguracion: [{
          icon: "mdi-home-city",
          title: 'Empresa',
          url: '/empresa'
        },
        {
          icon: "mdi-map-legend",
          title: 'Ubicación',
          url: '/ubicacion'
        }
      ]
    }),
    created() {
      this.editPerfil = JSON.parse(this.usuario)
    },
    computed: {
      ...mapState(["usuario"]),

      menu() {
        return this.subMenu();
      }
    },
    methods: {
      /**funcion para que se seleccione la obciondel menu desplegable */
      subMenu() {

        if ((this.$route.name == "Empresa") || (this.$route.name == "Ubicacion")) {
          this.configuracion = true;
        } else {
          this.configuracion = false;
        }

        if ((this.$route.name == "Equipo") || (this.$route.name == "Asignar") || (this.$route.name == "Tipo_Item") ||
          (this.$route.name == "Uso_Item") || (this.$route.name == "Tipo_Equipo") || (this.$route.name ==
          "Estado_Item") ||
          (this.$route.name == 'Estado_Remision') || (this.$route.name == "Fabricante")) {
          this.items = true;
        } else {
          this.items = false;
        }

        if ((this.$route.name == "Usuario") || (this.$route.name == "Rol") || (this.$route.name == "Cargo") || (
            this.$route.name == "Grupo") || (this.$route.name == "Tratamiento")) {
          this.accesos = true;
        } else {
          this.accesos = false;
        }

        if ((this.$route.name == "Inpeccion")) {
          this.procesos = true;
        } else {
          this.procesos = false;
        }

      }
    }
  }
</script>