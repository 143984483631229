import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/login'
  },
  /**Login */
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue')
  },
  /**Fin login */

  /**Vistas solo administrador */
  {
    path: '/cargo',
    name: 'Cargo',
    component: () => import('../views/administrador/Cargo.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/grupo',
    name: 'Grupo',
    component: () => import('../views/administrador/Grupo.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/rol',
    name: 'Rol',
    component: () => import('../views/administrador/Rol.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/tratamiento',
    name: 'Tratamiento',
    component: () => import('../views/administrador/Tratamiento.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/usuario',
    name: 'Usuario',
    component: () => import('../views/administrador/Usuario.vue'),
    meta: {
      requireAuth: true
    }
  },
  /**Fin vistas solo administrador */

  /**Vistas inspector */
  {
    path: '/inspeccion',
    name: 'Inpeccion',
    component: () => import('../views/inspector/Inspeccion_Equipo.vue'),
    meta: {
      requireAuth: true,
      admin: true
    }
  },
  /**Fin vistas inspector */

  {
    path: '/empresa',
    name: 'Empresa',
    component: () => import('../views/Empresa.vue'),
    meta: {
      requireAuth: true,
      admin: true
    }
  },
  {
    path: '/inicio',
    name: 'Inicio',
    component: () => import('../views/Inicio.vue'),
    meta: {
      requireAuth: true,
      admin: true
    }
  },
  
  {
    path: '/estado_item',
    name: 'Estado_Item',
    component: () => import('../views/Estado_Item.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/uso_item',
    name: 'Uso_Item',
    component: () => import('../views/Uso_Item.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/tipo_item',
    name: 'Tipo_Item',
    component: () => import('../views/Tipo_Item.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/estado_remision',
    name: 'Estado_Remision',
    component: () => import('../views/Estado_Remision.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/tipo_equipo',
    name: 'Tipo_Equipo',
    component: () => import('../views/Tipo_Equipo.vue'),
    meta: {
      requireAuth: true
    }

  },
  {
    path: '/fabricante',
    name: 'Fabricante',
    component: () => import('../views/Fabricante.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/asignar',
    name: 'Asignar',
    component: () => import('../views/Asignar.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/formato',
    name: 'Formato',
    component: () => import('../views/Formato.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/equipo',
    name: 'Equipo',
    component: () => import('../views/Equipo.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/ubicacion',
    name: 'Ubicacion',
    component: () => import('../views/Ubicacion.vue'),
    meta: {
      requireAuth: true
    }
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const rutaProtegida = to.matched.some(record => record.meta.requireAuth);
  // const admin = to.matched.some(record => record.meta.admin);
  if (store.state.token == '') {
    store.state.token = null
  }
  if (rutaProtegida && store.state.token === null) {
    // ruta protegida es true
    // token es nulo true, por ende redirigimos al inicio
    next({
      name: 'Login'
    })
  } else {
    let a = JSON.parse(store.state.usuario);
    if (a) {
      // let h = a['permiso'].filter(element => element == 1)
      // console.log(admin);
      // console.log(h);
    }

    // console.log(a['permiso']);
    // a['permiso'].forEach(element => {
    //   if (admin && (element != 1 || element != 2 || element != 3)) {
    //     console.log(element);
    //     next({
    //       name: ''
    //     })
    //   } 
    // });
    
    

    if ((store.state.token != null) && (to.name == "Login")) {
      next({
        name: 'Inicio'
      })
    }
    // En caso contrario sigue...
    next()
  }

})
export default router