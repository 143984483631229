<template>
    <!-- cards -->
    <v-row class="mb-5">
        <v-col cols="12" xl="4" sm="6">
            <v-card dark color="#10007d" to="/Equipo">
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-subtitle class="title font-weight-light">Total Ítems</v-card-subtitle>
                        <v-card-title class="headline text-h4">{{total}}</v-card-title>
                    </div>
                    <v-avatar class="ma-3" size="115" tile>
                        <v-icon size="100">mdi-clipboard-text-multiple</v-icon>
                    </v-avatar>
                </div>
            </v-card>
        </v-col>

        <v-col cols="12" xl="4" sm="6">
            <v-card dark color="#1F7087" to="/inspeccion">
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-subtitle class="title font-weight-light">Ítems Vencidos</v-card-subtitle>
                        <v-card-title class="headline text-h4">{{vencidos}}</v-card-title>
                    </div>
                    <v-avatar class="ma-3" size="115" tile>
                        <v-icon size="100">mdi-clipboard-alert</v-icon>
                    </v-avatar>
                </div>
            </v-card>
        </v-col>

        <v-col cols="12" xl="4" sm="6">
            <v-card dark color="#952175">
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-subtitle class="title font-weight-light">Proximos Mantenimientos</v-card-subtitle>
                        <v-card-title class="headline text-h4">{{proximoVence}}</v-card-title>
                    </div>
                    <v-avatar class="ma-3" size="115" tile>
                        <v-icon size="100">mdi-calendar</v-icon>
                    </v-avatar>
                </div>
            </v-card>
        </v-col>

        <!-- <v-col cols="12" xl="3" sm="6">
            <v-card dark color="#26c6da">
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-subtitle class="title font-weight-light">Remisiones</v-card-subtitle>
                        <v-card-title class="headline text-h3">{{remision}}</v-card-title>
                    </div>
                    <v-avatar class="ma-3" size="125" tile>
                        <v-icon size="100">mdi-file-document-multiple</v-icon>
                    </v-avatar>
                </div>
            </v-card>
        </v-col> -->
    </v-row>
</template>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    export default {
        name: "tarjeta",
        data: () => ({
            //   search: '',
            url_api: process.env.VUE_APP_URL_API,
            activos: [],
            total: 0,
            vencidos: 0,
            proximoVence: 0,
            remision: 0,
        }),
        created() {
            this.cardItem()
        },
        computed: {
            ...mapState(["token"]),
        },
        methods: {
            ...mapActions(['cerrarSesion']),

            async cardItem() {
                try {
                    await fetch(`${this.url_api}/item`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) { 
                                this.activos = response.body;
                                var fechaActual = (new Date(Date.now() - (new Date()).getTimezoneOffset() *
                                        60000))
                                    .toISOString().substr(0, 10);
                                var fechaActua = fechaActual.match(/(\d+)/g);

                                this.activos.forEach(element => {
                                    var fechaVence = element.vencimiento;
                                    var fechaVen = fechaVence.match(/(\d+)/g);
                                    if ((new Date(fechaActua[0], fechaActua[1] - 1, fechaActua[2])
                                            .getTime()) > (
                                            new Date(fechaVen[0], fechaVen[1] - 1, fechaVen[2])
                                            .getTime())) {
                                        ++this.vencidos
                                    }
                                })

                                this.total = this.activos.length
                                this.proximoVence = this.total - this.vencidos;
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },
        }
    }
</script>