import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null || localStorage.getItem('token'),
    usuario: null || localStorage.getItem('usuario')
  },
  
  mutations: {
    setToken(state, payload) {
      state.token = payload
    },
    setUsuario(state, payload) {
      state.usuario = payload
    },
  },

  actions: {
    async login({
      commit
    }, usuario) {
      try {
        await fetch(`${process.env.VUE_APP_URL_API}/usuario`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(usuario), // data can be `string` or {object}!
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .catch(error => console.error('Error:', error))
          .then(response => {
            if (response.body) {
              commit('setToken', response.body.token);
              commit('setUsuario', response.body.usuario);
              
              localStorage.setItem('token', response.body.token);
              localStorage.setItem('usuario', response.body.usuario);

              router.push({
                name: 'Inicio'
              });
              return response.body;

            } else if (response.error) {
              return response.error;
            }
          });
      } catch (error) {
        console.error('Error:', error);
      }
    },

    leerToken({
      commit
    }) {
      if (localStorage.getItem('token')) {
        commit('setToken', localStorage.getItem('token'))
      } else {
        commit('setToken', null)
        commit('setUsuario', null)
      }

    },

    cerrarSesion({
      commit
    }) {
      localStorage.removeItem('token')
      localStorage.removeItem('usuario')
      commit('setToken', null)
      commit('setUsuario', null)
      router.push({
        name: 'Login'
      })
    },
  },
  module: {}
})