<template>
  <!--Footer-->
  <v-footer dark padless style="border-bottom-left-radius: 50px">
    <v-card class="flex" flat tyle="border-bottom-left-radius: 70px !important">
      <v-container class="mt-5">
        <v-row justify="center">
          <v-col cols="12" class="d-flex flex-column justify-center align-center">
            <p class="font-weight-light text-h6">
              Todos los derechos reservados<span> - SAMS </span>
            </p>
          </v-col>
          <v-col class="d-flex flex-column justify-center align-center">
            <v-row>
              <p class="mr-2 mt-2">{{ new Date().getFullYear() }} - Vuetify</p>
              <p>
              <v-btn v-for="(i, index) in icons" v-bind:key="index" :href="i.url" dark icon>
                <span class="text-left">
                  <v-icon size="24px">
                    {{ i.icon }}
                  </v-icon>
                </span>
              </v-btn>
            </p>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-footer>
  <!--Footer/-->
</template>
<style scoped>
  .theme--dark.v-card {
    background-color: #1e1e1e00 !important;
  }
</style>
<script>
  export default {
    name: "Footer",
    data: () => ({
      icons: [
        {icon:"mdi-facebook", url:"https://www.facebook.com/pg/preventionworld.qhsesas"}, 
        {icon:"mdi-twitter", url:"https://twitter.com/pwqhse?lang=es"}, 
        // {icon:"mdi-linkedin", url:""}, 
        {icon:"mdi-instagram", url:"https://www.instagram.com/preventionworldqhsesas/"}], // iconos
    }),
  };
</script>