<template>
  <div>
    <!-- alerta CRUD -->
    <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
      {{ textoAlerta }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="alerta = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <!-- fin alerta CRUD -->

    <v-navigation-drawer v-if="width" dark v-model="drawer" color="#10007d" aosolute app>
      <NavContenido mini="false" />
    </v-navigation-drawer>

    <v-navigation-drawer v-else v-model="drawer" dark :mini-variant="mini" permanent color="#10007d"
      v-bind:class="{ 'nav-mini': mini }" app>
      <NavContenido :mini="mini" />
    </v-navigation-drawer>

    <v-app-bar color="#f2f2f2" class="black--text" style="border-top-left-radius: 100px" dark :elevation="0" absolute
      app>
      <v-btn v-if="width" class="mx-2 mr-5 black--text" fab dark color="transparent" :elevation="0"
        @click.stop="drawer = !drawer">
        <v-icon v-if="mini">mdi-menu</v-icon>
        <v-icon v-if="!mini">mdi-notification-clear-all</v-icon>
      </v-btn>

      <v-btn v-else class="mx-2 mr-5 black--text" fab dark color="transparent" :elevation="0" @click="btnMain">
        <v-icon v-if="mini">mdi-menu</v-icon>
        <v-icon v-if="!mini">mdi-notification-clear-all</v-icon>
      </v-btn>

      <v-toolbar-title>PreventionWorld</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-badge dot left>
          <v-icon class="black--text">mdi-bell</v-icon>
        </v-badge>
      </v-btn>

      <v-menu bottom min-width="250px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="deep-orange lighten-5" size="50">
              <v-img :src="url.src"></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-hover v-slot="{ hover }">
                <v-avatar color="deep-orange lighten-5" size="68" class="mb-2" style="margin-right: 0px;">
                  <v-img :src="url.src">
                    <v-expand-transition>
                      <v-row v-if="hover" class="transition-fast-in-fast-out v-card--reveal " align="center"
                        justify="center">
                        <v-file-input prepend-icon="mdi-camera" @change="vistaPrevia()" v-model="foto"
                          class="justify-center foto" dark style="background: rgba(16, 0, 125, 0.6)" light
                          accept="image/png, image/jpeg" hide-input>
                        </v-file-input>
                      </v-row>
                    </v-expand-transition>
                  </v-img>
                </v-avatar>
              </v-hover>
              <h3>{{ editPerfil.nombre }}</h3>
              <p class="text-caption mt-1">
                {{ editPerfil.usuario }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-dialog v-model="modalPerfil" max-width="900px" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed rounded text v-bind="attrs" v-on="on">
                    Editar Cuenta
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar color="primary" dark>
                    <v-card-title>
                      <span class="text-h5">{{ editPerfil.nombre + ' ' +editPerfil.apellido  }}</span>
                    </v-card-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-form ref="frmPerfil" v-model="validoPerfil" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="editPerfil.identificacion" :rules="regla.identificacion"
                              prepend-inner-icon="mdi-format-text" label="Identificación" outlined clearable required>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="editPerfil.nombre" :rules="regla.nombre"
                              prepend-inner-icon="mdi-format-text" label="Nombre" outlined clearable required>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="editPerfil.apellido" :rules="regla.apellido"
                              prepend-inner-icon="mdi-format-text" label="Apellido" outlined clearable required>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-dialog ref="dialog" v-model="modal" :return-value.sync="editPerfil.nacimiento" persistent
                              width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="editPerfil.nacimiento" :rules="regla.nacimiento"
                                  prepend-inner-icon="mdi-calendar-month" label="Fecha de nacimiento*" readonly
                                  v-bind="attrs" v-on="on" outlined required>
                                </v-text-field>
                              </template>
                              <v-date-picker year-icon="mdi-calendar-blank" prev-icon="mdi-skip-previous"
                                next-icon="mdi-skip-next" v-model="editPerfil.nacimiento" scrollable :max="fechaMaxima"
                                :show-current="fechaMaxima" locale="es">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal = false">
                                  Cancelar
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(editPerfil.nacimiento)">
                                  Aceptar
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="editPerfil.direccion" :rules="regla.direccion"
                              prepend-inner-icon="mdi-format-text" label="Dirección" outlined clearable required>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="editPerfil.telefono" :rules="regla.telefono"
                              prepend-inner-icon="mdi-format-text" label="Teléfono" outlined clearable required>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="editPerfil.email" :rules="regla.email"
                              prepend-inner-icon="mdi-format-text" label="Email" outlined clearable required>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-autocomplete v-model="editPerfil.departamento" :items="departamentos"
                              :rules="regla.departamento" item-text="nombre" item-value="Uid"
                              prepend-inner-icon="mdi-city" label="Deparmento*" outlined required>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-autocomplete v-model="editPerfil.municipio" :items="municipios" :rules="regla.municipio"
                              item-value="Uid" prepend-inner-icon="mdi-home-modern" item-text="nombre"
                              label="Municipio*" outlined required>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="editPerfil.contrasena" :rules="regla.contrasena"
                              :append-icon="showPerfil ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPerfil ? 'text' : 'password'" @click:append="showPerfil = !showPerfil"
                              prepend-inner-icon="mdi-format-text" label="Contraseña" outlined clearable required>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field v-model="editPerfil.contrasenaC" :rules="regla.contrasenaC"
                              :append-icon="showPerfil ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPerfil ? 'text' : 'password'" @click:append="showPerfil = !showPerfil"
                              prepend-inner-icon="mdi-format-text" label="Verificacion Contraseña" outlined clearable
                              required>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" class="mr-4" @click="cancelarPerfil()">
                            Cancelar
                          </v-btn>
                          <v-btn :disabled="!validoPerfil" color="success" class="mr-4" @click="perfil()">
                            Guardar
                          </v-btn>
                        </v-card-actions>
                      </v-form>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text @click="cerrarSesion">
                Desconectar
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<style scope>
  .nav-mini {
    width: 100px !important;
  }

  .nav-mini~header {
    left: 100px !important;
  }

  .main {
    padding: 64px 0px 0px 100px !important;
  }

  .foto div .v-input__icon {
    align-items: center;
    display: inline-flex;
    height: 68px;
    flex: 1 0 auto;
    justify-content: center;
    min-width: 68px;
    width: 68px;
  }

  .foto .v-input__prepend-outer {
    margin-right: 0;
  }

  .foto {
    padding-top: 0px;
    margin-top: 0px;
  }
</style>
<script>
  import {
    mapActions,
    mapState
  } from "vuex";
  import NavContenido from "./NavContenido"
  import imagen from "@/assets/Perfil.png";
  export default {
    name: "Nav",
    components: {
      NavContenido,
    },
    data: () => ({
      url_api: process.env.VUE_APP_URL_API,

      alerta: false,
      textoAlerta: '',
      colorAlerta: '',
      timeout: 2000,

      isEditing: false,
      model: null,
      url: {
        src: imagen,
      },
      foto: [{
        imagen
      }],
      width: null,
      modalPerfil: false,
      configuracion: false,
      distribucion: false,
      item: false,
      drawer: true,
      mini: true,
      modal: false,
      validoPerfil: false,
      showPerfil: false,
      departamentos: [],
      departamento: null,
      municipios: [],
      fechaMaxima: (new Date().getFullYear() - 18) + ((new Date(Date.now() - (new Date())
        .getTimezoneOffset() * 60000)).toISOString().substr(4, 6)),
      regla: {
        nombre: [
          v => !!v || 'El nombre es requerido.'
        ],
        apellido: [
          v => !!v || 'El apellido es requerido.'
        ],
        nacimiento: [
          v => !!v || 'El fecha de nacimiento es requerido.'
        ],
        departamento: [
          v => !!v || 'El departamento es requerido.'
        ],
        municipio: [
          v => !!v || 'El municipio es requerido.'
        ],
      },
      editPerfil: {},
    }),

    created() {

      this.editPerfil = JSON.parse(this.usuario)

      if ((this.editPerfil.foto != '') && (this.editPerfil.foto != null)) {
        this.url = {
          src: `${this.url_api}/files/foto/${this.editPerfil.usuario}/.Metadatos/${this.editPerfil.foto}`,
        }
        this.foto = [this.editPerfil.foto]
      }

      this.listarDepartamentos();
      this.listarMunicipios();
    },
    computed: {
      ...mapState(["token", "usuario"]),
    },
    mounted() {
      this.main();
      this.pantalla();
    },
    watch: {
      // modal perfil
      modalPerfil(val) {
        val || this.cancelarPerfil()
      },
    },
    methods: {
      btnMain() {
        this.mini = !this.mini
        this.main();
      },
      main() {
        let elemento = document.getElementById("main");
        // v-navigation-drawer
        if (this.mini == true) {
          elemento.className += " main";
        } else {
          elemento.classList.remove('main');
        }

      },
      vistaPrevia() {
        this.url = {};
        this.url = {
          src: URL.createObjectURL(this.foto),
        };
        this.editaPerfil();
      },

      pantalla() {
        this.width = window.innerWidth < 768
        if (this.width == true) {
          let elemento = document.getElementById("main");
          elemento.classList.remove('main');
        }
      },

      ...mapActions(['cerrarSesion']),

      cancelarPerfil() {
        this.modalPerfil = false;
      },

      async listarDepartamentos() {
        try {
          await fetch(`${this.url_api}/departamento`, {
              headers: {
                'Content-Type': 'application/json',
                "auth-token": this.token
              }
            }).then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => this.departamentos = response.body);
        } catch (error) {
          console.error('Error:', error)
        }
      },

      async listarMunicipios() {
        try {
          await fetch(`${this.url_api}/municipio`, {
              headers: {
                'Content-Type': 'application/json',
                "auth-token": this.token
              }
            }).then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
              if (response.body) {
                this.municipios = response.body;
              } else if (response.error == ('!token' || 'tokenNull')) {
                this.cerrarSesion();
              }
            });
        } catch (error) {
          console.error('Error:', error);
        }
      },

      async editaPerfil() {
        let formData = new FormData()
        formData.append('image', this.foto)
        formData.append('usuario', this.editPerfil.usuario)
        try {
          await fetch(`${this.url_api}/persona/foto_perfil`, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                "auth-token": this.token
              },
              body: formData
            }).then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
              if (response.body == 'exito') {
                this.alerta = false;
                this.textoAlerta = 'Tu foto se actualiza con Exito!';
                this.colorAlerta = 'success'
                this.alerta = true;
              } else if (response.error) {
                this.alerta = false;
                this.textoAlerta = 'Error al actualizar tu foto!';
                this.colorAlerta = 'error'
                this.alerta = true;
              }
              this.cancelarPerfil();
            });
        } catch (error) {
          console.error('Error:', error);
        }
      },

      async perfil() {
        if (this.$refs.frmPerfil.validate()) {
          try {
            await fetch(`${this.url_api}/persona/perfil`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  "auth-token": this.token
                },
                body: JSON.stringify(this.editPerfil)
              }).then(res => res.json())
              .catch(error => console.error('Error:', error))
              .then(response => {
                if (response.body == 'exito') {
                  this.alerta = false;
                  this.textoAlerta = 'Tus datos se actualizaron con Exito!';
                  this.colorAlerta = 'success'
                  this.alerta = true;
                } else if (response.error) {
                  this.alerta = false;
                  this.textoAlerta = 'Error al actualizar tus datos!';
                  this.colorAlerta = 'error'
                  this.alerta = true;
                }
                this.cancelarPerfil();
              });
          } catch (error) {
            console.error('Error:', error);
          }
        }
      }
    },
  };
</script>