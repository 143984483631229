var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list-item',{staticClass:"mt-3"},[_c('img',{staticClass:"pw-mundo",attrs:{"src":require("@/assets/Icono.png")}}),_c('img',{staticClass:"pw-logo",attrs:{"src":require("@/assets/Logo-sams-light.png")}})]),_c('v-list',{staticClass:"mt-10",attrs:{"dense":"","rounded":""}},[_c('v-list-item',{staticClass:"custom-tile",attrs:{"to":"/inicio"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Inicio")])],1)],1),_c('v-menu',{attrs:{"offset-x":"","transition":"slide-x-transition","close-on-content-click":false,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref ){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.editPerfil.permiso[0] == 0 )?_c('v-list-item',_vm._g(_vm._b({staticClass:"v-list-menu",class:{'v-list-item--active': _vm.accesos}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-key")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Accesos")])],1)],1):_vm._e()]}}])},_vm._l((_vm.menuAccesos),function(list,k){return _c('v-list',{key:k},[_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"black--text",domProps:{"textContent":_vm._s(list.title)}})],1)]},proxy:true}],null,true)},_vm._l((list.menu),function(item,key){return _c('v-list-item',{key:key,attrs:{"to":item.url,"link":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-title',{staticClass:"black--text",domProps:{"textContent":_vm._s(item.title)}})],1)}),1)],1)}),1),_c('v-menu',{attrs:{"offset-x":"","transition":"slide-x-transition","close-on-content-click":false,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref ){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"v-list-menu",class:{'v-list-item--active': _vm.items}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clipboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Items")])],1)],1)]}}])},_vm._l((_vm.menuItems),function(list,k){return _c('v-list',{key:k},[_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"black--text",domProps:{"textContent":_vm._s(list.title)}})],1)]},proxy:true}],null,true)},_vm._l((list.menu),function(item,key){return _c('v-list-item',{key:key,attrs:{"to":item.url,"link":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-title',{staticClass:"black--text",domProps:{"textContent":_vm._s(item.title)}})],1)}),1)],1)}),1),_c('v-menu',{attrs:{"offset-x":"","transition":"slide-x-transition","close-on-content-click":false,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref ){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"v-list-menu",class:{'v-list-item--active': _vm.procesos}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-sitemap")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Procesos")])],1)],1)]}}])},[_c('v-list',_vm._l((_vm.menuProcesos),function(proceso,key){return _c('v-list-item',{key:key,attrs:{"to":proceso.url,"link":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(proceso.icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(proceso.title)}})],1)}),1)],1),_c('v-menu',{attrs:{"offset-x":"","transition":"slide-x-transition","close-on-content-click":false,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref ){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.editPerfil.permiso[0] == 0 )?_c('v-list-item',_vm._g(_vm._b({staticClass:"v-list-menu",class:{'v-list-item--active': _vm.configuracion}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Configuración")])],1)],1):_vm._e()]}}])},[_c('v-list',_vm._l((_vm.menuConfiguracion),function(item,key){return _c('v-list-item',{key:key,attrs:{"to":item.url,"link":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)}),1)],1)],1),_vm._v(" "+_vm._s(_vm.menu)+" ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }